"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatCoAuthors = void 0;
var formatCoAuthors = function (authors) {
    if (!(authors === null || authors === void 0 ? void 0 : authors.length))
        return [];
    return authors.map(function (author) { return ({
        id: author.ID,
        avatar: author.user_avatar,
        name: author.display_name,
        nicename: author.user_nicename
    }); });
};
exports.formatCoAuthors = formatCoAuthors;
