import React, { useEffect, useMemo, useState } from 'react'
import axios from 'axios'
import Link from 'next/link'
import { AllHtmlEntities } from 'html-entities'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { KeywordGroupGroups, Layout, Post, Promotion, Promotions, Section, Topic } from '../../types'
import useAd from '../../utils/useAd'
import { getPostRender, renderRows } from '../../utils/sections'
import { formatCoAuthors } from '@krasia/core/utils/authors'
import { fetchViewPost, NotificationsResult } from '../../fetchers/post'
import { ErrorSection } from '../../components/ErrorSection'
import { Header, HeaderProps } from '../../components/Header'
import { Box } from '../../components/Box'
import { Divider } from '../../components/Divider'
import { Button } from '../../components/Button'
import { AppContainer } from '../../components/AppContainer'
import { Footer, FooterProps } from '../../components/Footer'
import { Link as LinkDeco } from '../../components/Link'
import config from '../../config'
import IN from '../../icons/IN'
import WA from '../../icons/WA'
import Bee from '../../icons/Bee'
import dynamic from 'next/dynamic'
import { PostNotifications } from '../../components'
import { renderDisplayRow } from '../../components/ArticleSection'
import { SectionTitle } from '../../components/SectionTitle'
import { Recent as IconRecent, Recent } from '../../icons'
import router from 'next/router'
import { Spinner, useMediaQuery } from '@chakra-ui/react'
import useShowTime from '../../utils/common'
import { MemeModal } from '../../components/MemeSection'
import Image from 'next/image'
import { AsideQrcode } from '../../components/AsideQrcode'
import { AsideWidget } from '../../components/AsideWidget'
import { AsideKeywords } from '../../components/AsideKeywords'

const DynamicComponent = dynamic<any>(() => import('../../components/ModalQR').then(mod => mod.ModalQr))

export type DetailProps = {
  headerProps: HeaderProps
  footerProps: FooterProps
  result: { section: Layout; data: Section }[]
  is404: boolean
  promotions: Promotions
  post: Post
  keywordGroups: KeywordGroupGroups
  error: boolean
  collectionIds: number[]
}

const entities = new AllHtmlEntities()

export const Detail: React.FC<DetailProps> = props => {
  const { post, result, promotions, error, headerProps, footerProps, keywordGroups, collectionIds = [] } = props

  const [isMobile] = useMediaQuery('(max-width: 768px)', {
    ssr: true,
    fallback: false // return false on the server, and re-evaluate on the client side
  }) // 用于判断是否是移动端
  const [isOpenQr, setOpenQr] = React.useState(false)
  const [showMemeModal, setShowMemeModal] = React.useState(false)
  const [memeTitle, setMemeTitle] = React.useState(null)
  const [memeAuthor, setMemeAuthor] = React.useState(null)
  const [currentPage, setCurrentPage] = React.useState(0)
  const [notification, setNotification] = React.useState<NotificationsResult>()

  const adBottom = useAd({
    key: 'featured-bottom',
    promotions
  })

  const adTop = useAd({
    key: 'article-page-top',
    promotions
  })

  const adAside = useAd({
    key: 'article-aside',
    promotions
  })

  const adAside2 = useAd({
    key: 'article-aside-2',
    promotions
  })

  const [mobilePostAds, setMobilePostAds] = React.useState<Promotions>()
  useEffect(() => {
    if (promotions && post && post.id) {
      if (promotions['mobile-article-nav-bottom'] && promotions['mobile-article-nav-bottom'].length > 0) {
        const oldAds = promotions['mobile-article-nav-bottom']
        // console.log('oldAds: ', oldAds)
        const ads = []
        oldAds.forEach(ad => {
          // console.log('ad: ', ad)
          if (!ad.ad?.exclude_posts || !ad.ad?.exclude_posts?.includes(post.id as never)) {
            ads.push(ad)
          }
        })

        const newPromotions = {
          'mobile-article-nav-bottom': ads
        }

        setMobilePostAds(newPromotions)
      }
    }
  }, [promotions, post])

  const adMobileNavBottom = useAd({
    key: 'mobile-article-nav-bottom',
    promotions: mobilePostAds
  })

  const [collectionAds, setCollectionAds] = React.useState<Promotions>()

  useEffect(() => {
    if (promotions && collectionIds && collectionIds.length > 0) {
      const collectionPromotions: Promotion[] = []

      const allCOllectionPromotions = promotions['collection-aside']

      if (allCOllectionPromotions && allCOllectionPromotions.length > 0) {
        allCOllectionPromotions.forEach(item => {
          collectionIds.forEach(id => {
            if (item.collections.includes(id) && item.ad.show_on_collection_posts) {
              collectionPromotions.push(item)
            }
          })
        })
      }
      const newPromotions = {
        'collection-aside': collectionPromotions
      }

      setCollectionAds(newPromotions)
    }
  }, [collectionIds, promotions])

  const collectionAd = useAd({
    key: 'collection-aside',
    promotions: collectionAds
  })

  const postDate = useShowTime(post.date)

  const wechatShareTitle = post?.acf?.wechat_title
    ? entities.decode(`${post.acf.wechat_title} | 36氪出海`)
    : entities.decode(`${post.title.rendered} | 36氪出海`)

  React.useEffect(() => {
    function is_weixin() {
      const ua = navigator.userAgent.toLowerCase()

      return ua.indexOf('micromessenger') != -1
    }
    if (typeof window !== undefined && is_weixin()) {
      fetch('https://console.letschuhai.com/wp-json/wp/v2/wechat/configs?url=' + window.location.href, {
        method: 'GET',
        headers: {
          'Content-Type': 'text/plain'
        }
      })
        .then(response => response.json())
        .then(result => {
          // console.log('result: ', result)
          // 在此处写获取数据之后的处理逻辑
          if (result.appId) {
            wx.config({
              debug: false,
              appId: result.appId,
              timestamp: result.timestamp,
              nonceStr: result.nonceStr,
              signature: result.signature,
              jsApiList: [
                'hideMenuItems',
                'updateAppMessageShareData',
                'updateTimelineShareData',
                'onMenuShareAppMessage',
                'onMenuShareTimeline'
                // 所有要调用的 API 都要加到这个列表中
              ]
            })
          }
        })
        .catch(function () {
          // console.log('updateAppMessageShareData error:', error)
        })
        .finally(() => {
          wx.ready(function () {
            wx.updateAppMessageShareData({
              title: wechatShareTitle, // 分享标题
              desc: post.meme
                ? entities.decode(post.meme.meme).replace(/<[^>]+>/g, '')
                : entities.decode(post.excerpt.rendered), // 分享描述
              link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
              imgUrl: post.meme
                ? post.meme.cover
                : post.featured_image?.sizes?.post_cover?.source_url
                ? post.featured_image?.sizes?.post_cover?.source_url
                : post.featured_image.source_url // 分享图标
              // success: function (res) {
              //   // 设置成功
              //   console.log('chengg: ', res)
              // }
              // fail: function () {
              //   // console.log('updateAppMessageShareData error:', error)
              // }
            })
            wx.updateTimelineShareData({
              title: wechatShareTitle, // 分享标题
              link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
              imgUrl: post.meme
                ? post.meme.cover
                : post.featured_image?.sizes?.post_cover?.source_url
                ? post.featured_image?.sizes?.post_cover?.source_url
                : post.featured_image.source_url // 分享图标
              // success: function () {
              //   // 设置成功
              //   // alert('res on update timeline share data: ' + JSON.stringify(res))
              //   // console.log('img url: ', post.featured_image?.sizes.post_cover.source_url)
              // },
              // fail: function () {
              //   // alert('updateAppMessageShareData error:' + error)
              //   // console.log('updateAppMessageShareData error:', error)
              // }
            })
          })
          wx.error(function () {
            // alert('init wechat share failed, reason: ' + JSON.stringify(res))
            // console.log('res: ', res)
          })
        })
    }
  }, [post, wechatShareTitle])
  React.useEffect(() => {
    document.body.style.backgroundImage = 'none'

    return () => {
      document.body.style.backgroundImage = "url('https://static.letschuhai.com/static/images/background-image.png')"
    }
  }, [])

  const [postTaxonomies, setPostTaxonomies] = React.useState<Topic[]>([])
  useEffect(() => {
    if (post && post.taxonomies) {
      const { company, industry, post_tag } = post.taxonomies
      let taxonomies: any[] = []

      if (industry && industry.length > 0) {
        taxonomies = [...taxonomies, ...industry]
      }
      if (company && company.length > 0) {
        taxonomies = [...taxonomies, ...company]
      }
      if (post_tag && post_tag.length > 0) {
        taxonomies = [...taxonomies, ...post_tag]
      }

      setPostTaxonomies(taxonomies)
    }

    return () => {
      setPostTaxonomies([])
    }
  }, [post])

  React.useEffect(() => {
    if (post && process.env.NODE_ENV === 'production') {
      axios.post(fetchViewPost(), {
        post_id: post.id
      })
    }
  }, [post])

  /**
   * 获取文章通知
   */
  React.useEffect(() => {
    if (post && typeof window !== undefined && window.innerWidth < 768) {
      fetch(`${process.env.NEXT_PUBLIC_VIEW_API_HOST}/v2/posts/notifications`, {
        method: 'GET',
        headers: {
          'Content-Type': 'text/plain'
        }
      })
        .then(response => response.json())
        .then(res => {
          if (res.status === 'success') {
            setNotification(res.target)
          }
        })
    }
  }, [post])

  /**
   * 获取最新文章列表(仅移动端)
   */
  const [latestPosts, setLatestPosts] = React.useState<Post[]>([])
  const [loadingLatest, setLoadingLatest] = React.useState<boolean>(true)
  React.useEffect(() => {
    if (isMobile) {
      fetch(`${process.env.NEXT_PUBLIC_VIEW_API_HOST}/v2/posts`, {
        method: 'GET',
        headers: {
          'Content-Type': 'text/plain'
        }
      })
        .then(response => response.json())
        .then(res => {
          setLatestPosts(res)
          setLoadingLatest(false)
        })
    }
  }, [post, isMobile])

  /**
   * 控制 Notifications 位置
   */
  const [headerOnBottom, setHeaderOnBottom] = useState<boolean>(false)

  /**
   * 展示最新文章列表
   */
  const renderLatestPosts = () => {
    if (latestPosts && latestPosts.length > 0) {
      const posts = latestPosts.map(getPostRender())
      if (loadingLatest) {
        return (
          <Box width='100%' height={'200px'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
            <Spinner color='white' />
          </Box>
        )
      }
      return (
        <Box px={'16px'}>
          <Divider my={'32px'} />
          <Box as='header'>
            <SectionTitle icon={<IconRecent />} title='最新文章' />
          </Box>
          <Box>{renderDisplayRow(posts)}</Box>
          <Button
            mt={'24px'}
            mb={'84px'}
            colorScheme='blue'
            width={'100%'}
            size='lg'
            onClick={() => {
              router.push('/recent')
            }}
          >
            查看更多文章
          </Button>
        </Box>
      )
    }
    return <Box mb={'16px'}> </Box>
  }

  const authors = useMemo(() => {
    if (post) {
      if (post?.acf?.co_authors?.length) {
        const coAuthors = formatCoAuthors(post.acf.co_authors)
        return [post.author, ...coAuthors]
      }

      return [post.author]
    }

    return []
  }, [post])

  if (error) {
    return <ErrorSection />
  }

  const industry = post?.taxonomies?.industry

  let categoryUrl = `/topic/${post?.taxonomies?.topic?.[0]?.slug}`
  let category = post?.taxonomies?.topic?.[0]?.name || ''

  if (industry) {
    categoryUrl = `/industry/${industry?.[0]?.slug}`
    category = industry?.[0]?.name || ''
  }

  const cover =
    post.featured_image?.sizes?.post_cover || post.featured_image || post.featured_image?.sizes?.featured_card
  const seo = {
    title: entities.decode(`${post.title.rendered} | 36氪出海`),
    description: entities.decode(post.excerpt.rendered),
    cover
  }

  const handleCloseQr = () => {
    setOpenQr(false)
  }

  const handleSocial = (type: string) =>
    function (evt: any) {
      evt.preventDefault()

      if (type === 'whatsApp') {
        setOpenQr(true)
        return
      }

      const url = `${process.env.NEXT_PUBLIC_HOST}/${post.slug}`
      const title = seo.title

      let result: string

      switch (type) {
        case 'linkedin':
          result = `https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${encodeURIComponent(
            title
          )}&source=KrASIA`
          break
        case 'bee':
          result = `http://service.weibo.com/share/share.php?url=${url}&appkey=&${encodeURIComponent(
            title
          )}=Description&pic=${post.meme ? post.meme.cover : seo.cover.source_url}&ralateUid=&language=zh_cn`
          break
        default:
          result = url
      }

      const popup = window.open(result, 'Kr Asia Share', 'height=420, width=550')

      window.focus && popup.focus()
    }

  const listShare = [
    {
      icon: WA,
      title: 'WhatsApp',
      type: 'whatsApp',
      size: 20
    },
    {
      icon: Bee,
      title: 'Bee',
      type: 'bee'
    },
    {
      icon: IN,
      title: 'Linkedin',
      type: 'linkedin',
      size: 16
    }
  ]

  let self = `${process.env.NEXT_PUBLIC_HOST}/${post.slug}`
  if (typeof window !== 'undefined') {
    self = window.location.href
  }

  const ShareWidget = () => {
    return (
      <Box display='flex' flexDirection='row' justifyContent='center' alignItems={'center'} mt={'24px'}>
        <Box as='span' mr={'12px'}>
          分享:{' '}
        </Box>
        {listShare.map(l => {
          const Icon = l.icon
          return (
            <Button
              minWidth='0'
              height='32px'
              width='32px'
              display='inline-flex'
              color='#fff'
              fontSize={l.size || '24px'}
              textAlign='center'
              borderRadius='16px'
              border='1px solid #fff'
              // backgroundColor='socialBackground'
              aria-label={l.title}
              key={l.type}
              variant='unstyled'
              onClick={handleSocial(l.type)}
              mr='16px'
              transition={'all .3s ease-in-out'}
              _last={{ mr: 0 }}
              _hover={{
                backgroundColor: '#fff',
                color: '#212832',
                borderColor: '#fff'
              }}
            >
              <Icon />
            </Button>
          )
        })}
      </Box>
    )
  }

  const MemeAuthorInfo = (
    <>
      <Box display={'flex'} justifyContent='right' mt={'16px'} alignItems={'center'} fontSize={'14px'}>
        {post.meme.author?.pre ? (
          <Box mr={'8px'} opacity={0.75}>
            {'——领读语来自'}
            {post.meme.author.pre}
          </Box>
        ) : (
          <Box opacity={0.75}>{'——领读语来自 '}</Box>
        )}
        <Box
          textStyle='articleCategory'
          fontSize={'14px'}
          textDecoration={'underline'}
          cursor={'pointer'}
          onClick={() => {
            if (!isMobile) {
              setMemeAuthor(post.meme.author.id)
              setCurrentPage(0)
              setMemeTitle(`${post.meme.author?.name}·领读`)
              setShowMemeModal(true)
            } else {
              router.push(`/memes/?author=${post.meme.author.id}&name=${post.meme.author?.name}`)
            }
          }}
          style={{ textTransform: 'none' }}
        >
          {post.meme.author?.name}
        </Box>
      </Box>
      <Box
        display={'flex'}
        justifyContent='right'
        alignItems={'center'}
        textStyle='articleCategory'
        fontSize={'12px'}
        mt={'12px'}
        textDecoration={'underline'}
        cursor={'pointer'}
        onClick={() => {
          if (!isMobile) {
            setMemeTitle(`36氪出海·领读`)
            setCurrentPage(0)
            setShowMemeModal(true)
          } else {
            router.push(`/memes`)
          }
        }}
      >
        查看「36氪出海·领读」的全部内容 &gt;&gt;
      </Box>
    </>
  )

  const renderMobileHeaderWithoutMeme = (
    <>
      {notification ? (
        <PostNotifications id={post.id} notification={notification} headerOnBottom={headerOnBottom} />
      ) : null}

      <Box as='header' textAlign='left'>
        <Box
          mb={{
            base: '25px',
            md: '50px'
          }}
          mx={'-16px'}
          mt={'-16px'}
        >
          <Image
            src={`${post.featured_image?.source_url}`}
            width={1080}
            height={560}
            layout='responsive'
            objectFit='cover'
            priority={true}
            alt={post.featured_image?.description}
          />
        </Box>
        <Link href={categoryUrl} prefetch={false}>
          <Box as='a' textStyle='articleCategory' href={categoryUrl}>
            <span dangerouslySetInnerHTML={{ __html: category }} />
          </Box>
        </Link>
        <Box
          mx='auto'
          mt={{ base: '16px', md: '20px' }}
          as='h1'
          textStyle='articleTitle'
          fontSize={{
            base: '20px',
            lg: '36px'
          }}
          lineHeight={{
            base: '30px',
            lg: '42px'
          }}
          fontWeight={'bold'}
        >
          <span dangerouslySetInnerHTML={{ __html: post.title.rendered }} />
        </Box>

        <Box
          fontSize={{
            base: '14px',
            md: '16px'
          }}
          lineHeight={{ base: '24px', md: '24px' }}
          mt={{
            base: '20px',
            md: '20px'
          }}
          as='p'
          fontWeight='400'
          display={'flex'}
          alignItems={'center'}
          justifyContent={'left'}
          color={'rgba(255,255,255,0.5)'}
        >
          {authors.map((author, index) => (
            <React.Fragment key={author.id}>
              <LinkDeco
                noStyle={true}
                textDecoration='underline'
                display={{ base: 'block', md: 'inline' }}
                href={config.paths.authorDetail(author.nicename)}
                color={'rgba(255,255,255,0.5)'}
              >
                {author.name || author.nicename}
              </LinkDeco>
              {index < authors.length - 1 && <span>&#44;</span>}&nbsp;
            </React.Fragment>
          ))}{' '}
          {` | `}
          {post.date ? (
            <Box ml='5px' as='span' color={'rgba(255,255,255,0.5)'}>
              <time dateTime={new Date(post.date).toISOString()}>{postDate}</time>
            </Box>
          ) : null}
        </Box>

        <Box
          mx='auto'
          maxWidth='800px'
          display='block'
          mt={{
            base: '24px'
          }}
          px={'24px'}
          as='strong'
          textStyle='articleIntro'
          textAlign='left'
          fontSize={{
            base: '16px'
          }}
          lineHeight={{
            base: '32px'
          }}
          position={'relative'}
          _before={{
            content: '"“"',
            position: 'absolute',
            left: '0',
            top: '0',
            fontFamily: 'sans-serif',
            opacity: '0.75',
            fontSize: '36px',
            fontWeight: 'bold'
          }}
        >
          <span dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }} />
        </Box>
      </Box>

      <Divider borderColor='border' mt={{ base: '24px', md: '48px', sm: '24px' }} />
    </>
  )

  const renderMobileHeaderWithMeme = (
    <>
      {notification ? (
        <PostNotifications id={post.id} notification={notification} headerOnBottom={headerOnBottom} />
      ) : null}

      <Box as='header' textAlign='left'>
        <Box
          mb={{
            base: '25px',
            md: '50px'
          }}
          mx={'-16px'}
          mt={'-16px'}
        >
          <Image
            src={`${cover?.source_url}!post.card.cover`}
            width={1080}
            height={500}
            layout='responsive'
            priority={true}
            alt={post.featured_image?.description}
          />
        </Box>

        <Link href={categoryUrl} prefetch={false}>
          <Box as='a' textStyle='articleCategory' href={categoryUrl}>
            <span dangerouslySetInnerHTML={{ __html: category }} />
          </Box>
        </Link>
        <Box
          mx='auto'
          mt={{ base: '16px', md: '20px' }}
          as='h1'
          textStyle='articleTitle'
          fontSize={{
            base: '20px',
            lg: '36px'
          }}
          lineHeight={{
            base: '30px',
            lg: '42px'
          }}
          fontWeight={'bold'}
        >
          <span dangerouslySetInnerHTML={{ __html: post.title.rendered }} />
        </Box>

        <Box
          fontSize={{
            base: '14px',
            md: '16px'
          }}
          lineHeight={{ base: '24px', md: '24px' }}
          mt={{
            base: '20px',
            md: '20px'
          }}
          as='p'
          fontWeight='400'
          display={'flex'}
          alignItems={'center'}
          justifyContent={'left'}
          color={'rgba(255,255,255,0.5)'}
        >
          {authors.map((author, index) => (
            <React.Fragment key={author.id}>
              <LinkDeco
                noStyle={true}
                textDecoration='underline'
                display={{ base: 'block', md: 'inline' }}
                href={config.paths.authorDetail(author.nicename)}
                textStyle='articleCategory'
              >
                {author.name || author.nicename}
              </LinkDeco>
              {index < authors.length - 1 && <span>&#44;</span>}&nbsp;
            </React.Fragment>
          ))}{' '}
          {` | `}
          {post.date ? (
            <Box ml='5px' as='span' color={'rgba(255,255,255,0.5)'}>
              <time dateTime={new Date(post.date).toISOString()}>{postDate}</time>
            </Box>
          ) : null}
        </Box>

        <Box flex={1} display={'flex'} flexDirection='column' mt={{ base: '36px', md: '24px' }}>
          <Box
            textStyle='articleText'
            position={'relative'}
            textAlign={'justify'}
            pl={'0px'}
            _before={{
              content: '"“"',
              position: 'absolute',
              left: '-16px',
              top: '-16px',
              width: '80px',
              height: '80px',
              // background: '#f00',
              fontFamily: 'serif',
              opacity: '0.1',
              fontSize: '128px',
              lineHeight: '80px',
              fontWeight: 'bold'
            }}
          >
            <span dangerouslySetInnerHTML={{ __html: post.meme.meme }} />
          </Box>
          {MemeAuthorInfo}
        </Box>
      </Box>

      <Divider borderColor='border' mt={{ base: '24px', md: '48px', sm: '24px' }} />
    </>
  )

  const renderHeaderWithMeme = (
    <Box as='header' textAlign='left' mx='auto'>
      <Link href={categoryUrl} prefetch={false}>
        <Box as='a' textStyle='articleCategory' href={categoryUrl}>
          <span dangerouslySetInnerHTML={{ __html: category }} />
        </Box>
      </Link>
      <Box
        mt={{ base: '24px', md: '20px' }}
        as='h1'
        textStyle='articleTitle'
        fontSize={{
          base: '24px',
          lg: '36px'
        }}
        lineHeight={{
          base: '34px',
          lg: '42px'
        }}
      >
        <span dangerouslySetInnerHTML={{ __html: post.title.rendered }} />
      </Box>
      <Box
        fontSize={{
          base: '18px',
          md: '16px'
        }}
        lineHeight={{ base: '24px', md: '24px' }}
        mt={{
          base: '20px',
          md: '20px'
        }}
        fontWeight='400'
        display={'flex'}
        alignItems={'center'}
      >
        {authors.map((author, index) => (
          <React.Fragment key={author.id}>
            <LinkDeco
              noStyle={true}
              textDecoration='underline'
              display={{ base: 'block', md: 'inline' }}
              href={config.paths.authorDetail(author.nicename)}
              textStyle='articleCategory'
              fontSize={'16px'}
            >
              {author.name || author.nicename}
            </LinkDeco>
            {index < authors.length - 1 && <span>&#44;</span>}&nbsp;
          </React.Fragment>
        ))}
        {post.date ? (
          <Box ml='5px' as='span' display='flex' justifyContent='center'>
            |&nbsp; <time dateTime={new Date(post.date).toISOString()}>{postDate}</time>
          </Box>
        ) : null}
      </Box>
      <Box mt={'32px'} display={'flex'} alignItems={'center'}>
        <Box
          key={post?.meme?.cover}
          as={LazyLoadImage}
          width={'240px'}
          height={'240px'}
          objectFit='cover'
          src={`${post?.meme.cover}!square.medium`}
          alt={post.title?.rendered}
        />
        <Box flex={1} display={'flex'} flexDirection='column' pl={'32px'}>
          <Box
            textStyle='articleText'
            position={'relative'}
            textAlign={'justify'}
            _before={{
              content: '"“"',
              position: 'absolute',
              left: '-24px',
              top: '-24px',
              width: '80px',
              height: '80px',
              // background: '#f00',
              fontFamily: 'serif',
              opacity: '0.1',
              fontSize: '128px',
              lineHeight: '80px',
              fontWeight: 'bold'
            }}
          >
            <span dangerouslySetInnerHTML={{ __html: post.meme.meme }} />
          </Box>
          {MemeAuthorInfo}
        </Box>
      </Box>
      <Divider borderColor='border' mt={{ base: '24px', md: '48px', sm: '24px' }} />
      {MemeModal({
        list: [],
        title: memeTitle,
        icon: <Recent />,
        visible: showMemeModal,
        onClose(status) {
          setShowMemeModal(status || false)
          setMemeAuthor(null)
          setMemeTitle('')
          setCurrentPage(-1)
        },
        author: memeAuthor,
        currentPage
      })}
    </Box>
  )

  const renderHeaderWithoutMeme = (
    <Box as='header'>
      <Link href={categoryUrl} prefetch={false}>
        <Box as='a' textStyle='articleCategory' href={categoryUrl}>
          <span dangerouslySetInnerHTML={{ __html: category }} />
        </Box>
      </Link>
      <Box
        mx='auto'
        mt={{ base: '24px', md: '20px' }}
        as='h1'
        textStyle='articleTitle'
        fontSize={{
          base: '24px',
          lg: '36px'
        }}
        lineHeight={{
          base: '34px',
          lg: '42px'
        }}
      >
        <span dangerouslySetInnerHTML={{ __html: post.title.rendered }} />
      </Box>

      <Box
        fontSize={{
          base: '18px',
          md: '16px'
        }}
        lineHeight={{ base: '24px', md: '24px' }}
        mt={{
          base: '20px',
          md: '20px'
        }}
        as='p'
        fontWeight='400'
      >
        {authors.map((author, index) => (
          <React.Fragment key={author.id}>
            <LinkDeco
              noStyle={true}
              textDecoration='underline'
              display={{ base: 'block', md: 'inline' }}
              href={config.paths.authorDetail(author.nicename)}
            >
              {author.name || author.nicename}
            </LinkDeco>
            {index < authors.length - 1 && <span>&#44;</span>}&nbsp;
          </React.Fragment>
        ))}
        {post.date ? (
          <Box ml='5px' as='span'>
            <time dateTime={new Date(post.date).toISOString()}>{postDate}</time>
          </Box>
        ) : null}
      </Box>

      <Box
        mt={{
          base: '25px',
          md: '50px'
        }}
      >
        <Image
          src={`${post.featured_image?.source_url}`}
          width={1080}
          height={560}
          layout='responsive'
          objectFit='cover'
          priority={true}
          alt={post.featured_image?.description}
        />
      </Box>

      <Box
        mx='auto'
        maxWidth='800px'
        display='block'
        mt={{
          base: '22px',
          md: '46px'
        }}
        as='strong'
        textStyle='articleIntro'
        textAlign='center'
      >
        <span dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }} />
      </Box>

      <Divider borderColor='border' mt={{ base: '24px', md: '48px', sm: '24px' }} />
    </Box>
  )

  const renderDesktopHeader = <>{post.meme ? renderHeaderWithMeme : renderHeaderWithoutMeme}</>

  const renderMobileHeader = <>{post.meme ? renderMobileHeaderWithMeme : renderMobileHeaderWithoutMeme}</>

  const renderPostTaxonomies = () => {
    if (postTaxonomies.length > 0) {
      return (
        <Box
          mt={{
            base: '24px',
            md: '48px'
          }}
        >
          <Box as='h5' textStyle='articleSubTitle' mb='10px'>
            <span dangerouslySetInnerHTML={{ __html: '相关关键词：' }} />
          </Box>
          <Box display='flex' flexWrap='wrap'>
            {postTaxonomies.map(
              (item, index) =>
                item.taxonomy !== 'post_tag' && (
                  <Box
                    key={index}
                    as='a'
                    href={`${item.taxonomy === 'post_tag' ? 'tag' : item.taxonomy}/${item.slug}`}
                    textStyle='articleTag'
                    mr='10px'
                    mb='10px'
                    display='inline-block'
                    _hover={{
                      color: 'primary'
                    }}
                    fontSize={{
                      base: '14px',
                      md: '16px'
                    }}
                  >
                    <span dangerouslySetInnerHTML={{ __html: item.name }} />
                  </Box>
                )
            )}
          </Box>
        </Box>
      )
    }
  }

  return (
    <React.Fragment>
      <Header
        {...headerProps}
        onSwitch={status => {
          setHeaderOnBottom(status)
        }}
      />

      {isOpenQr && <DynamicComponent url={self} isOpen={isOpenQr} onClose={handleCloseQr} />}
      <Box display={{ base: 'block', sm: 'none' }}>
        {promotions && promotions['article-page-top'] && promotions['article-page-top'].length > 0 && (
          <Box mt={{ base: '30px', md: '0' }}>{adTop.autoRender}</Box>
        )}
      </Box>
      <Box display={{ base: 'block', md: 'none', lg: 'none' }}>
        {promotions &&
          promotions['mobile-article-nav-bottom'] &&
          promotions['mobile-article-nav-bottom'].length > 0 && (
            <Box mt={{ base: '8px', md: '0' }}>{adMobileNavBottom.autoRender}</Box>
          )}
      </Box>
      <AppContainer
        pt={{ base: '16px', lg: '40px' }}
        pb='20px'
        pl={{ base: '16px', md: '32px', xl: '85px' }}
        pr={{ base: '16px', md: '32px', xl: '85px' }}
        display={'flex'}
        flexDir={{ base: 'column', lg: 'row' }}
      >
        <Box
          width={{ base: '100%', md: '100%', lg: '65%', xl: '65%' }}
          flex={1}
          pr={{ base: '0', md: '0px', sm: '0', lg: '55px' }}
        >
          {isMobile ? renderMobileHeader : renderDesktopHeader}
          {!isMobile && <ShareWidget />}
          <Box
            className={`entry-content ${post.meme ? 'entry-with-meme-content' : ''}`}
            mx={'auto'}
            mt={{
              base: '24px'
            }}
            width='100%'
            textStyle='articleText'
          >
            {post.content ? <div dangerouslySetInnerHTML={{ __html: post.content.rendered }} /> : '文章正文没有内容'}
            {renderPostTaxonomies()}
          </Box>
          <Box textAlign='center' mt='50px' display={{ md: 'none' }}>
            <Box
              fontWeight='500'
              fontSize={{
                base: '16px'
              }}
              lineHeight={{
                base: '24px'
              }}
            >
              分享
            </Box>
            {!isMobile && (
              <Box display='flex' justifyContent='center' mt='10px'>
                {listShare.map(l => {
                  const Icon = l.icon
                  return (
                    <Button
                      minWidth='0'
                      height='32px'
                      width='32px'
                      mx='9px'
                      display='inline-flex'
                      color='white'
                      fontSize={l.size || '24px'}
                      textAlign='center'
                      borderRadius='8px'
                      backgroundColor='socialBackground'
                      aria-label={l.title}
                      key={l.type}
                      variant='unstyled'
                      onClick={handleSocial(l.type)}
                    >
                      <Icon />
                    </Button>
                  )
                })}
              </Box>
            )}
          </Box>
        </Box>

        <Box
          width={{ base: '100%', md: '100%', lg: '35%', xl: '35%' }}
          maxW={'400px'}
          display={{ base: 'block', md: 'none', lg: 'block' }}
          position={'relative'}
        >
          <Box
            position={'sticky'}
            top='84px'
            width={'100%'}
            height={'fit-content'}
            display={{ base: 'none', md: 'block' }}
            paddingLeft={'40px'}
          >
            {keywordGroups && keywordGroups.length > 0 && (
              <AsideWidget style={{ marginBottom: '24px' }}>
                <AsideKeywords keywordGroups={keywordGroups} />
              </AsideWidget>
            )}

            {collectionIds && collectionIds.length > 0 && <AsideWidget>{collectionAd.autoRender}</AsideWidget>}

            <AsideWidget>{adAside.autoRender}</AsideWidget>
            <AsideWidget>{adAside2.autoRender}</AsideWidget>
            <AsideWidget>
              <AsideQrcode />
            </AsideWidget>
          </Box>
        </Box>
      </AppContainer>

      {!isMobile ? (
        <AppContainer
          pt='25px'
          pb='100px'
          pl={{ base: '16px', md: '32px', xl: '85px' }}
          pr={{ base: '16px', md: '32px', xl: '85px' }}
        >
          {renderRows(
            result,
            {},
            {
              containerProps: {
                paddingInline: { base: '0 !important', md: '0 !important' }
              }
            }
          ).map((r, key) => {
            return (
              <Box key={key} className='test'>
                {r}
              </Box>
            )
          })}
          {adBottom.autoRender && adBottom.autoRender}
        </AppContainer>
      ) : (
        renderLatestPosts()
      )}
      <Footer {...footerProps} />
    </React.Fragment>
  )
}
