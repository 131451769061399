import { Box } from '@chakra-ui/react'
import { ReactNode } from 'react'
import { BoxProps } from '../Box'

interface AsideWidgetProps extends BoxProps {
  children: ReactNode
}

export const AsideWidget: React.FC<AsideWidgetProps> = props => {
  const { children, ...rest } = props

  return <Box {...rest}>{children}</Box>
}
