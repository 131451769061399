import { Box } from '@chakra-ui/react'
import { KeywordGroup, KeywordGroupGroups } from '../../types'
import { useState } from 'react'
import { Link } from '../Link'
import React from 'react'

export type AsideKeywordsProps = {
  keywordGroups: KeywordGroupGroups
}

export const AsideKeywords: React.FC<AsideKeywordsProps> = props => {
  const { keywordGroups } = props

  const [current, setCurrent] = useState<number>(0)
  const [keywords, setKeywords] = useState<KeywordGroup>(keywordGroups?.[0]?.keywords)

  return (
    <>
      {keywordGroups && keywordGroups.length > 0 && (
        <Box>
          <Box display={'flex'} flexDirection={'row'}>
            <Box mr={'8px'}>{'// '}</Box>
            {keywordGroups.map((keywordGroup, index) => {
              if (current === index) {
                return (
                  <React.Fragment key={index}>
                    <Box key={index} color={'primary'} marginRight={'12px'}>
                      {keywordGroup.name}
                    </Box>
                    {index !== keywordGroups.length - 1 && <Box mr={'12px'}>|</Box>}
                  </React.Fragment>
                )
              }

              return (
                <React.Fragment key={index}>
                  <Box
                    key={index}
                    onClick={() => {
                      setCurrent(index)
                      setKeywords(keywordGroup.keywords)
                    }}
                    cursor={'pointer'}
                    marginRight={'12px'}
                  >
                    {keywordGroup.name}
                  </Box>
                  {index !== keywordGroups.length - 1 && <Box mr={'12px'}>|</Box>}
                </React.Fragment>
              )
            })}
          </Box>
          {keywords && keywords.length > 0 && (
            <Box display={'flex'} mt={'16px'} flexWrap={'wrap'}>
              {keywords.map((keyword, index) => {
                return (
                  <Box key={index} mr={'8px'} mb={'8px'} fontSize={'14px'}>
                    <Link
                      href={keyword.url}
                      title={keyword.text}
                      color={'#fff'}
                      border={'1px solid #fff'}
                      lineHeight={'28px'}
                      padding={'0 12px'}
                      borderRadius={'14px'}
                      transition={'.3s ease all'}
                      _hover={{ textDecor: 'none', bg: 'primary', color: 'black', borderColor: 'primary' }}
                      target='_blank'
                    >
                      {keyword.text}
                    </Link>
                  </Box>
                )
              })}
            </Box>
          )}
        </Box>
      )}
    </>
  )
}
