import React from 'react'
import { Box, Text } from '@chakra-ui/layout'
import Image from 'next/image'

export type SectionTitleProps = {
  icon?: React.ReactNode
  iconImage?: string
  title: string
}

export function SectionTitle(props: SectionTitleProps) {
  const { icon, title, iconImage } = props
  return (
    <Text as='h2' textStyle='sectionTitle' display='flex' mb='15px' alignItems='center'>
      {icon && (
        <Box mt='-4px' fontSize='1em' mr='16px'>
          {icon}
        </Box>
      )}
      {iconImage && (
        <Box mr='16px'>
          <Image objectFit='contain' width={26} height={26} alt='' src={iconImage} />
        </Box>
      )}
      <span dangerouslySetInnerHTML={{ __html: title }} />
    </Text>
  )
}
