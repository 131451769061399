import React from 'react'

function WA(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 24 24' fill='currentColor' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20.708 18.623C22.102 17.626 23 16.14 23 14.498c0-3.02-2.97-5.459-6.628-5.459-3.657 0-6.627 2.448-6.627 5.459 0 3.02 2.97 5.458 6.627 5.458.761 0 1.486-.109 2.164-.299l.192-.027c.129 0 .239.036.349.1l1.448.825.128.045c.12 0 .22-.1.22-.218l-.036-.163-.303-1.106-.027-.136a.518.518 0 01.201-.354zM8.957 3C4.557 3 1 5.938 1 9.556c0 1.977 1.072 3.754 2.75 4.95a.51.51 0 01.22.427l-.028.163-.357 1.324-.046.19c0 .145.12.263.266.263l.156-.045 1.741-.997a.842.842 0 01.422-.118l.239.036a9.683 9.683 0 002.594.363l.44-.01a4.955 4.955 0 01-.266-1.604c0-3.301 3.245-5.976 7.25-5.976l.431.01C16.207 5.393 12.918 3 8.957 3zm5.206 10.618a.876.876 0 01-.88-.87c0-.481.395-.87.88-.87.486 0 .88.389.88.87 0 .48-.394.87-.88.87zm4.419 0a.875.875 0 01-.88-.87c0-.481.394-.87.88-.87.485 0 .88.389.88.87 0 .48-.395.87-.88.87zM6.298 8.504a1.058 1.058 0 01-1.063-1.052c0-.58.477-1.052 1.063-1.052.587 0 1.064.472 1.064 1.052 0 .58-.477 1.052-1.064 1.052zm5.308 0a1.058 1.058 0 01-1.063-1.052c0-.58.476-1.052 1.063-1.052s1.063.472 1.063 1.052a1.064 1.064 0 01-1.063 1.052z'
      />
    </svg>
  )
}

export default WA
