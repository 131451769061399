import React from 'react'
import { useRouter } from 'next/router'
import { Detail as DetailScene } from '../../scenes/Detail'
import { Promotions, Post, Layout, Section, MenuValue, KeywordGroupGroups } from '../../types'
import { footerLinks, headerLinks } from '../../config'
import { Loading } from '../../components/Loading'

export interface DetailProps {
  headerMenu: { [key: string]: MenuValue }
  result: { section: Layout; data: Section }[]
  is404: boolean
  promotions: Promotions
  post: Post
  keywordGroups: KeywordGroupGroups
  error: boolean
  collectionIds: number[]
}

const Detail: React.FC<DetailProps> = props => {
  const { headerMenu, post, keywordGroups, collectionIds, ...otherProps } = props
  const router = useRouter()

  if (router.isFallback) {
    return <Loading />
  }

  return (
    <DetailScene
      headerProps={{ headerMenu, links: headerLinks }}
      footerProps={{ listLink: footerLinks }}
      post={post}
      keywordGroups={keywordGroups}
      collectionIds={collectionIds}
      {...otherProps}
    />
  )
}

export default Detail
