import React from 'react'

function IN(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 24 24' fill='currentColor' {...props}>
      <path d='M6.363 22.257c0 .41-.334.743-.748.743H2.432a.746.746 0 01-.748-.743V9c0-.41.335-.743.748-.743h3.184c.413 0 .748.332.748.743v13.256zM4.024 7.009A3.015 3.015 0 007.05 4.004 3.015 3.015 0 004.024 1 3.015 3.015 0 001 4.004 3.015 3.015 0 004.024 7.01zM23 22.317a.685.685 0 01-.688.683h-3.416a.685.685 0 01-.687-.683v-6.218c0-.928.273-4.065-2.44-4.065-2.106 0-2.533 2.148-2.618 3.111v7.172a.685.685 0 01-.688.683H9.159a.685.685 0 01-.688-.683V8.94c0-.377.308-.683.688-.683h3.304c.38 0 .688.306.688.683v1.156c.78-1.163 1.94-2.062 4.41-2.062 5.47 0 5.439 5.077 5.439 7.866v6.416z' />
    </svg>
  )
}

export default IN
