import * as React from 'react'

function Bee(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 24 24' fill='none' {...props}>
      <path
        d='M21.981 8.806c.085 1.117-.101 2.451-.84 2.52-1.2.107-.573-1.217-.557-2.015.042-2.312-1.93-3.918-3.858-3.918-.547 0-1.817.372-1.62-.781.09-.51.52-.5.95-.559 3.247-.43 5.697 1.803 5.925 4.753zM16.673 11.495c1.398.766 3.071 1.143 2.795 3.413-.07.543-.393 1.27-.728 1.733-2.36 3.307-9.479 4.695-13.92 2.186-1.494-.846-3.034-2.08-2.795-4.536.207-2.116 1.626-3.753 3.018-5.146C6.37 7.816 7.773 6.774 9.68 6.29c2.072-.52 2.683 1.207 2.125 2.908 1.201-.08 3.74-1.42 4.862-.111.5.58.308 1.61.005 2.408zm-1.509 5.37c.447-.505.898-1.282.893-2.18-.005-2.78-3.507-3.807-6.26-3.583-1.508.122-2.518.44-3.58 1.063-.861.505-1.876 1.329-2.125 2.573-.564 2.791 2.47 4.105 4.696 4.253 2.572.17 5.149-.739 6.376-2.126z'
        fill='currentColor'
      />
      <path
        d='M19.245 8.807c.117.85-.128 1.59-.617 1.621-.813.053-.51-.558-.558-1.286-.031-.447-.403-.968-.727-1.117-.638-.297-1.62.218-1.62-.67 0-.659.573-.59.892-.616 1.418-.107 2.465.872 2.63 2.068zM12.704 14.405c1.19 3.605-5.016 5.28-6.153 2.18-.76-2.068 1.063-3.716 2.965-3.918 1.62-.165 2.827.659 3.188 1.738zm-2.853.617c.255.436.834.122.78-.223-.058-.404-.785-.346-.78.223zM8.84 16.92c1.042-.25 1.185-2.217-.393-1.845-1.318.309-1.062 2.196.393 1.845z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Bee
